<template>
  <div class="challenge-answer">
    <a-row type="flex" justify="center">
      <a-col :span="23" :lg="16">
        <a-row
          style="
            background-color: #ffffff;
            box-shadow: 0px 0px 17px -8px rgba(14, 155, 210, 20);
            border-radius: 8px;
          "
          :style="{ padding: fullWidth > 992 ? '20px 30px' : '20px 10px' }"
        >
          <a-col :span="24">
            <div style="display: flex; align-items: center">
              <!-- <div
                style="
                  flex: 1;
                  text-align: left;
                  font-size: 18px;
                  color: #333333;
                  font-weight: bold;
                  margin-bottom: 20px;
                "
              >
                第{{ currentIndex + 1 }}题
                <span>{{ currentIndex + 1 }}/{{ challengeList.length }}</span>
              </div> -->
              <div
                style="
                  flex: 1;
                  text-align: right;
                  font-size: 18px;
                  color: #333333;
                  font-weight: bold;
                  margin-bottom: 20px;
                "
              >
                倒计时：{{ singleTime }}
              </div>
            </div>
          </a-col>
          <div class="test-content">
            <template v-if="empty">
              <a-row
                type="flex"
                justify="center"
                style="margin-bottom: 10px; margin-top: 10px; min-height: 400px"
              >
                <el-empty :description="description"></el-empty>
              </a-row>
            </template>
            <template v-else>
              <a-row
                :gutter="24"
                style="margin-top: 20px; min-height: 500px"
                v-loading="loading"
                element-loading-text="加载中"
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.2)"
              >
                <!-- 测试题 -->
                <div class="test">
                  <div
                    v-for="(item, index) in challengeList"
                    :key="index"
                    style="
                      position: absolute;
                      top: 50%;
                      left: 50%;
                      transform: translate(-50%, -50%);
                    "
                  >
                    <div
                      class="test-options"
                      v-if="index === currentIndex ? true : false"
                      style="font-size: 18px; font-weight: bold"
                    >
                      {{ item.questionContent }}
                      <div style="margin-top: 10px">
                        <div
                          style="text-align: left"
                          v-for="(v, i) in item.answerList"
                          :key="i"
                        >
                          <div
                            class="options"
                            :class="
                              correctAnswer === true && v.correctAnswer === true
                                ? 'trueActive'
                                : falseAnswer === true &&
                                  falseIndex === i &&
                                  v.correctAnswer === false &&
                                  'falseActive'
                            "
                          >
                            <!-- 单选题 -->
                            <el-radio-group @change="handleAnswer" v-model="getAnswer">
                              <el-radio
                                :label="item.answerList[i].optionFlag"
                                style="font-weight: bold"
                                >{{ v.optionFlag }}、{{ v.optionContent }}</el-radio
                              >
                            </el-radio-group>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 答题结束框 -->
                <div
                  class="btn"
                  style="
                    width: 100%;
                    margin-top: 30px;
                    display: flex;
                    justify-content: space-around;
                  "
                >
                  <div>
                    <el-dialog
                      title="挑战结束"
                      style="font-weight: bold"
                      :visible.sync="dialogVisible"
                      width="40%"
                      :before-close="getScorehandleClose"
                      destroy-on-close
                    >
                      <div style="font-weight: bold; font-size: 24px">
                        本次答对{{ okCount }}题
                      </div>
                      <div style="margin-top: 20px">历史最高记录{{ maxTrueCount }}</div>
                      <span slot="footer" class="dialog-footer">
                        <el-button @click="handleOnceAgain()">再来一次</el-button>
                        <el-button type="primary" @click="handleCompeteTest()"
                          >结束本局</el-button
                        >
                      </span>
                    </el-dialog>
                  </div>
                </div>
              </a-row>
            </template>
          </div>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  created() {
    this.getLegalCompetitionList();
  },
  data() {
    return {
      challengeList: [],
      loading: true,
      empty: false,
      currentIndex: 0,
      sumTime: "",
      // score: 0,
      dialogVisible: false,
      getAnswer: "",
      okCount: 0,
      errorCount: 0,
      singleTime: 45, // 单个题目倒计时时间
      timer: null,
      totalCount: 0,
      maxTrueCount: 0,
      correctAnswer: false,
      falseAnswer: false,
      falseIndex: -1,
    };
  },
  computed: {
    ...mapState(["fullWidth"]),
    currentAnswerList() {
      return this.challengeList[this.currentIndex].answerList;
    },
  },
  methods: {
    sortAnswerAction(list) {
      return list.sort((item1, item2) => {
        return item1.optionFlag > item2.optionFlag
          ? 1
          : item1.optionFlag < item2.optionFlag
          ? -1
          : 0;
      });
    },
    getLegalCompetitionList() {
      this.$HTTP
        .httpToken({
          url: "/classroom/getQuestionBankListByType",
          method: "post",
          data: {
            questionBankType: 1,
          },
        })
        .then((res) => {
          console.log("法治比赛", res);
          this.loading = false;
          if (res.code == "0" && res.data && res.data.length > 0) {
            this.empty = false;
            this.challengeList = res.data;
            this.challengeList.forEach((item) => {
              item.answerList = item.answerList.sort((item1, item2) => {
                return item1.optionFlag > item2.optionFlag
                  ? 1
                  : item1.optionFlag < item2.optionFlag
                  ? -1
                  : 0;
              });
            });
            // 总题数
            this.totalCount = res.data.length;
            // 获取到题目就开始倒计时
            this.runLastTimeText();
          } else {
            if (this.pageIndex == 1) {
              this.empty = true;
              this.description = "暂无数据";
            } else {
              this.empty = false;
            }
          }
        })
        .catch((e) => {
          this.loading = false;
          if (this.pageIndex == 1) {
            this.empty = true;
            this.description = e;
          } else {
            this.empty = false;
          }
        });
    },
    handleAnswer(value) {
      console.log("value :>> ", value);
      let arr = this.currentAnswerList.map((v, i) => {
        return v.optionFlag;
      });

      arr.forEach((v, i) => {
        if (v === value) {
          console.log("i :>> ", i);
          this.falseIndex = i;
          if (this.currentAnswerList[i].correctAnswer === true) {
            this.correctAnswer = true;
          } else if (this.currentAnswerList[i].correctAnswer === false) {
            this.falseAnswer = true;
          }
        }
      });

      // 用户只要一点击选项就停止定时器
      clearInterval(this.timer);
      // 再对用户答对和打错题目个数进行统计
      this.currentAnswerList.forEach((v, i) => {
        if (v.optionFlag === value) {
          this.correctAnswer = v.correctAnswer;
          console.log("this.correctAnswer :>> ", this.correctAnswer);
          if (v.correctAnswer) {
            this.okCount++;
            this.score += v.score;
          } else {
            this.errorCount++;
          }
        }
      });
      // 如果错题超过2个就弹出测试结束框 显示成绩
      if (this.errorCount === 2) {
        console.log("总分", this.okCount);
        // 先保存答题记录
        this.saveAnswerChallenge();
        // 再弹出总成绩框
        this.dialogVisible = true;
        clearInterval(this.timer);
        // 并不执行后面的代码
        return;
      }
      // 只要点击答案就切换到下一题
      this.getAnswer = value;

      console.log("this.okCount :>> ", this.okCount); //答对
      console.log("errorCount :>> ", this.errorCount); //答错
      // 用户选择答案后 延时一秒后再显示下一题
      setTimeout(() => {
        if (this.currentIndex === this.challengeList.length - 1) {
          clearInterval(this.timer);
          this.dialogVisible = true;
          return;
        }
        // 跳往下一题
        this.currentIndex++;
        // 定时器重新计时
        this.singleTime = 45;
        // 单选选项清空
        this.getAnswer = "";
        // 重新开启定时器
        this.correctAnswer = false;
        this.falseAnswer = false;
        this.falseIndex = -1;
        this.runLastTimeText();
      }, 1000);
    },

    runLastTimeText() {
      this.timer = setInterval(() => {
        this.singleTime--;
        if (this.singleTime < 0) {
          if (this.getAnswer == "") {
            // 如果时间到 用户还没有选择答案 就显示下一题
            this.errorCount++;
            console.log("时间到", this.errorCount);
            // 如果两道题内都没有选择且时间到
            if (this.errorCount >= 2) {
              console.log("答题结束");
              // 保存答题记录
              this.saveAnswerChallenge();
              // 弹出测试结束框 显示成绩
              this.dialogVisible = true;
              // 清除定时器
              clearInterval(this.timer);
              return;
            }
          }
          // 倒计时到后 时间重新显示为45
          this.singleTime = 45;
          if (this.currentIndex === this.challengeList.length - 1) {
            clearInterval(this.timer);
            this.dialogVisible = true;
            return;
          }
          // 显示下一道题
          this.currentIndex++;
          // 清除定时器
          clearInterval(this.timer);
          // 重新开始计时
          this.runLastTimeText();
        }
      }, 1000);
    },
    // 保存答题记录
    saveAnswerChallenge() {
      this.$HTTP
        .httpToken({
          url: "/classroom/saveSingletonCompetitionAnswerRecode",
          method: "post",
          data: {
            okCount: this.okCount,
            errorCount: this.errorCount,
            totalCount: this.totalCount,
            type: 1,
            cityCode: 520500,
          },
        })
        .then((res) => {
          if (res.code == "0" && res.data) {
            console.log("保存记录成功");
            this.maxTrueCount = res.data.recodeOKMaxCount;
          }
        })
        .catch((e) => {});
    },
    // 再来一次
    handleOnceAgain() {
      // 选对选错样式
      this.correctAnswer = false;
      this.falseAnswer = false;
      this.falseIndex = -1;
      // 点击再来一次后清除上一次的定时器
      clearInterval(this.timer);
      // 隐藏弹出框
      this.dialogVisible = false;
      // 重新从第一题开始答
      this.currentIndex = 0;
      // 倒计时时间重置
      this.singleTime = 45;
      // 清空上一次所选的答案
      this.getAnswer = "";
      // 答对题数重置为0
      this.okCount = 0;
      // 答错题数重置为0
      this.errorCount = 0;
      // 重新获取题库
      this.getLegalCompetitionList();
      // this.runLastTimeText();
    },
    // 点击确定取消答题
    handleCompeteTest() {
      this.dialogVisible = false;
      this.$router.push("/legalcompetition/legalcard");
    },
    getScorehandleClose(done) {
      this.$elementConfirm("确定退出答题吗？")
        .then((_) => {
          done();
          this.$router.push("/legalcompetition");
        })
        .catch((_) => {});
    },
  },
  destroyed() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-radio__original,
::v-deep .el-radio__input,
::v-deep .el-radio__inner {
  height: 0;
  width: 0;
}

::v-deep span.el-radio__inner {
  height: 0 !important;
  width: 0 !important;
}

.options {
  width: 100%;
  height: 50px;
  border: 1px solid #c5e6ef;
  border-radius: 30px;
  margin-bottom: 20px;
}

.el-radio-group,
.el-radio {
  width: 100%;
}

::v-deep .el-radio__label {
  display: inline-block;
  width: 100%;
  height: 50px;
  padding-left: 20px;
  line-height: 50px;
  text-align: left;
}

.trueActive {
  width: 100%;
  height: 50px;
  background: #51d2be;
  border-radius: 30px;
  // border: 1px solid #37b6df;
}
.falseActive {
  background: #fd6161 !important;
  border: 1px solid #fd6161;
  border-radius: 30px;
  width: 100%;
  height: 50px;
}

::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: #fff;
}
</style>
